import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "comment" ]

  connect() {}

  handleClick() {
    $('#js-dummy-text').addClass('hidden')
    $('#js-form-wrapper').removeClass('hidden')
    $('#comment_body').focus()
    $("html, body").animate({ scrollTop: $(document).height() }, "fast");
  }
}
