// All the things
import Turbolinks from 'turbolinks';
import * as ActiveStorage from 'activestorage';
import 'jquery';
import '../css/application';
import 'expose-loader?$!jquery';
import {} from 'jquery-ujs'
import Moment from 'moment'
import 'alpinejs'
import 'controllers'
import 'datatables.net'

Turbolinks.start()
ActiveStorage.start()
